import { 
    GET_JOGOS,
    GET_JOGO_BY_ID,
    CLEAR_JOGO_BY_ID,
    ADICIONAR_GOLO,
    FINALIZAR_JOGO,
    INICIA_JOGO,
    APAGAR_MARCADOR
} from '../types';


export default function jogosReducer(state={},action){
    switch(action.type){
        case GET_JOGOS:
            return { ...state, ...action.payload }
        case GET_JOGO_BY_ID:
                if(action.payload.length === 3)
                    return { ...state, jogo: action.payload[0][0], marcadores: action.payload[1], jogadores: action.payload[2] }
                else
                    return state;
        case CLEAR_JOGO_BY_ID:
            return { ...state, jogo: action.payload, marcadores: action.payload, jogadores: action.payload }
        case ADICIONAR_GOLO:
            return { ...state, updatejogo: !state.updatejogo }
        case APAGAR_MARCADOR:
            return { ...state, updatejogo: !state.updatejogo }
        case FINALIZAR_JOGO:
                return state;
        case INICIA_JOGO:
                return state;
        default:
            return state
    }
}