import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import MainLayout from './hoc/mainLayout';
import Jogos from './components/jogos';
import DetalheJogo from './components/jogos/detalheJogo';
import FormPrincipal from './components/inscricoes/formPrincipal';
import Success from './components/inscricoes/success';
import FormPassword from './components/inscricoes/formPassword';


const Routecomp = () => (
    <BrowserRouter>
        <MainLayout>
            <Routes>
                {/*
                <Route path="/jogos/admin/:id" element={<DetalheJogo admin={true}/>}/>
                <Route path="/admin/jogos/" element={<Jogos admin={true}/>}/>                
                <Route path="/jogos/:id" element={<DetalheJogo />}/>
                <Route path="/resultados" element={<Jogos />}/>
                <Route path="/" element={<Jogos />}/>
                */}
                <Route path="/inscricoes" element={<FormPrincipal/>}/>
                <Route path="/success" element={<Success />}/>
                <Route path="/" element={<FormPassword />}/>
            </Routes>
        </MainLayout>
    </BrowserRouter>
)
    

export default Routecomp