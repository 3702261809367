import React from 'react';
import Routecomp from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/styles.css'
import { Provider } from 'react-redux';
import ReduxStore from './store';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <Provider store={ReduxStore()}>
    <Routecomp />
    </Provider>
  </React.StrictMode>
);