import * as api from '../../api';
import { 
    GET_ESCALOES,
    GET_JOGOS,
    GET_JOGO_BY_ID,
    GET_CLASSIFICACAO,
    CLEAR_JOGO_BY_ID,
    ADICIONAR_GOLO,
    FINALIZAR_JOGO,
    INICIA_JOGO,
    GET_EQUIPA,
    GRAVAR_EQUIPA,
    RESET_INSCRICOES,
    APAGAR_MARCADOR
} from '../types';


/*////////////////////////////////////
            POST
/////////////////////////////////////*/

export const getEscaloes = () => ({
    type: GET_ESCALOES,
    payload: api.getEscaloes()
})

export const getJogos = () => ({
    type: GET_JOGOS,
    payload: api.getJogos()
})

export const adicionarGolo = (id,obj) => ({
    type: ADICIONAR_GOLO,
    payload: api.adicionarGolo(id,obj)
})

export const removerMarcador = (id,obj) => ({
    type: APAGAR_MARCADOR,
    payload: api.removerMarcador(id,obj)
})

export const finalizaJogo = (id) => ({
    type: FINALIZAR_JOGO,
    payload: api.finalizaJogo(id)
})

export const iniciaJogo = (id) => ({
    type: INICIA_JOGO,
    payload: api.iniciaJogo(id)
})

export const getClassificacao = () => ({
    type: GET_CLASSIFICACAO,
    payload: api.getClassificacao()
})

export const getJogoById = (id) => ({
    type: GET_JOGO_BY_ID,
    payload: api.getJogoById(id)
})

export const clearJogoById = () => ({
    type: CLEAR_JOGO_BY_ID,
    payload: {}
})

export const getEquipa = (id) => ({
    type: GET_EQUIPA,
    payload: api.getEquipa(id)
})

export const gravarEquipa = (id,data,file) => ({
    type: GRAVAR_EQUIPA,
    payload: api.gravarEquipa(id,data,file)
})

export const resetInscricoes = () => ({
    type: RESET_INSCRICOES,
    payload: ''
})
