import { combineReducers } from 'redux';
import escaloes from './escaloes_reducer';
import jogos from './jogos_reducer';
import classificacao from './classificacao_reducer';
import inscricoes from './inscricoes_reducer';

const appReducers = combineReducers({
    escaloes,jogos, classificacao, inscricoes
})

export default appReducers;